import { createSlice } from '@reduxjs/toolkit';
import { getTransactionData, updateTransactions } from '../../data/mongo';
import { loadSessionItems, storeSessionItems } from '../../data/fetchCalls';

const initialState = {
  transactions: [],
  transactionContent: '',
  transactionSearch: '',
  editTransaction: false,
  currentFilter: 'show_all',
  categoryFilter: 'show_all',
  entityFilter: 'show_all',
};



const sendUpdate = async(action) => {
  const data = {
    [action.payload.field]: action.payload.value
  };

  updateTransactions(action.payload.id, data);
}

function findClosestPrevDate(transactions, target){
  const targetDate = new Date(target);
  const previousDates = transactions.transactionData.filter(e => ( targetDate  - new Date(e.date)) > 0);
  const sortedPreviousDates =  previousDates.sort((a,b) => new Date(b.date) - new Date(a.date));
  
  let itemToReturn = null;

  if (sortedPreviousDates) {
    const trans = sortedPreviousDates[0];
    if (trans) {
      itemToReturn = transactions.transactionData.findIndex((a) => a.id === trans.id);
    }
  }
  return itemToReturn;
}

export const TransactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    SearchTransaction: (state, action) => {
      state.transactionSearch = action.payload;
    },
    SelectTransaction: (state, action) => {
      state.transactionContent = action.payload;
    },
    DeleteTransaction: (state, action) => {
      const index = state.transactions.findIndex((transaction) => transaction.id === action.payload);
      state.transactions.splice(index, 1);
      const currentTransactions = loadSessionItems("transactions") || [];
      const indexOfArrayToChange = currentTransactions.transactionData.findIndex(t => t.id === action.payload);
      currentTransactions.transactionData.splice(indexOfArrayToChange, 1);
        
      storeSessionItems("transactions", currentTransactions);
    },
    toggleStarredTransaction: (state, action) => {
      state.transactions = state.transactions.map((transaction) =>
      transaction.id === action.payload ? { ...transaction, starred: !transaction.starred } : transaction,
      );
    },
    isEdit: (state) => {
      state.editTransaction = !state.editTransaction;
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.categoryFilter = action.payload;
    },
    setEntityFilter: (state, action) => {
      state.entityFilter = action.payload;
    },
    UpdateTransaction: {
      reducer: (state, action) => {
        //this is where we send an update to the database
        sendUpdate(action);
        state.transactions = state.transactions.map((transaction) =>
        transaction.id === action.payload.id
            ? { ...transaction, [action.payload.field]: action.payload.value }
            : transaction,
        );

        const currentTransactions = loadSessionItems("transactions") || [];
        const indexOfArrayToChange = currentTransactions.transactionData.findIndex(t => t.id === action.payload.id);
        currentTransactions.transactionData[indexOfArrayToChange][action.payload.field] = action.payload.value;
        
        storeSessionItems("transactions", currentTransactions);
      },
      prepare: (id, field, value) => {
        return {
          payload: { id, field, value },
        };
      },
    },
    addTransaction: {
      reducer: (state, action) => {
        const currentTransactions = loadSessionItems("transactions") || [];
        const indexToInsert = findClosestPrevDate(currentTransactions, action.payload.date)
        
        if (indexToInsert) {
          state.transactions.splice(indexToInsert, 0, action.payload);
          currentTransactions.transactionData.splice(indexToInsert, 0, action.payload);  
        } else {
          state.transactions.push(action.payload);
          currentTransactions.transactionData.push(action.payload);
        }

        storeSessionItems("transactions", currentTransactions);
      },
      prepare: (
        id,
        name,
        notes,
        amount,
        category,
        entity,
        date,
      ) => {
        return {
          payload: {
            id,
            name,
            notes,
            amount,
            category,
            entity,
            date,
            deleted: false,
          },
        };
      },
    },
  },
});

export const {
  getTransactions,
  SearchTransaction,
  isEdit,
  SelectTransaction,
  DeleteTransaction,
  toggleStarredTransaction,
  UpdateTransaction,
  addTransaction,
  setVisibilityFilter,
  setCategoryFilter,
  setEntityFilter,
} = TransactionSlice.actions;


export const fetchTransactions = (type, value, fromDate, toDate, limit) => async (dispatch) => {
  try {
    const response = await getTransactionData(type, value, fromDate, toDate, limit);

    dispatch(getTransactions(response.transactionData));
    
  } catch (err) {
    throw new Error(err);
  }
};

export default TransactionSlice.reducer;
