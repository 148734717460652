import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { firebase } from './Firebase';
import { getUserByUid } from '../../data/mongo';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: 'Firebase',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  sendPasswordReset: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  onceGetUsers: () => Promise.resolve(),
  CreateUser: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  useEffect(
    () =>
      firebase.auth().onIdTokenChanged((user) => {
        if (user || user?.exists) {
          getUserByUid().then((response) => {
            setUserInfo(response);
            sessionStorage.setItem("userInfo", JSON.stringify(response));
          });

          firebase.auth().currentUser.getIdToken(true).then((resp) => {
              const tokenInfo = {
                token: resp,
                datetime: new Date()
              }
              sessionStorage.setItem('auth', JSON.stringify(tokenInfo));
          });
        }

      }),
    [dispatch],
  );

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          // firebase.auth().currentUser.getIdToken(true).then((resp) => {
          //     sessionStorage.setItem('auth', resp);
          // });
          // getUserByUid().then((response) => {
          //   sessionStorage.setItem("userInfo", JSON.stringify(response));
          // });
          
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
              },
            },
          });
        } else {
          setUserInfo({exists: false});
          
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [dispatch],
  );

  // Sign Up
  const createUserWithEmailAndPassword = (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);
    
  // Sign In
  // const signInWithEmailAndPassword = (email, password) => {
  //   firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
  //     .then(() => {
  //       return firebase.auth().signInWithEmailAndPassword(email, password);
  //     })
  // }

  const signInWithEmailAndPassword = (email, password) => 
    firebase.auth().signInWithEmailAndPassword(email, password);

  const sendPasswordReset = (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  // Sign out
  const logout = () => {
    sessionStorage.clear();
    firebase.auth().signOut();
    navigate('/');
  }

  const CreateUser = (id, username, email) =>
    firebase.database().ref(`users/${id}`).set({
      username,
      email,
    });
  const onceGetUsers = () => firebase.database().ref('users').once('value');

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        sendPasswordReset,
        CreateUser,
        onceGetUsers,
        logout,
        userInfo,
        setUserInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
