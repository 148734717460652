import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import AuthGuard from '../components/authGurad/AuthGuard';
import SubscriptionGuard from '../components/authGurad/SubscriptionGuard';

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Pages ****/
/*const About = Loadable(lazy(() => import('../views/About')));
const Alerts = Loadable(lazy(() => import('../views/ui/Alerts')));
const Badges = Loadable(lazy(() => import('../views/ui/Badges')));
const Buttons = Loadable(lazy(() => import('../views/ui/Buttons')));
const Cards = Loadable(lazy(() => import('../views/ui/Cards')));
const Grid = Loadable(lazy(() => import('../views/ui/Grid')));
const Tables = Loadable(lazy(() => import('../views/ui/Tables')));
const Forms = Loadable(lazy(() => import('../views/ui/Forms')));
const Breadcrumbs = Loadable(lazy(() => import('../views/ui/Breadcrumbs')));*/

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const Recover = Loadable(lazy(() => import('../views/auth/Recover')));

/***** AREA Pages ****/
const Profile = Loadable(lazy(() => import('../views/UserProfile')));
//const Payment = Loadable(lazy(() => import('../views/Payment')));
const Account = Loadable(lazy(() => import('../views/Account')));
const ConnectExternal = Loadable(lazy(() => import('../views/ConnectExternal')));
const ManageExternal = Loadable(lazy(() => import('../views/ManageExternal')));
// const Transactions = Loadable(lazy(() => import('../views/Transactions')));
const Reports = Loadable(lazy(() => import('../views/Reports')));
const Entities = Loadable(lazy(() => import('../views/Entities')));
const Property = Loadable(lazy(() => import('../views/Property')));
const AddProperty = Loadable(lazy(() => import('../views/AddProperty')));
const Welcome = Loadable(lazy(() => import('../views/Welcome')));
const TransactionsNew = Loadable(lazy(() => import('../views/apps/transactions/Transactions')));
/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', exact: true, element: <Welcome /> },
      { path: '/account', exact: true, element: <Account /> },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <SubscriptionGuard>
          <FullLayout />
        </SubscriptionGuard>
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to="/entities" /> },
      // { path: '/dashboard', element: <Navigate to="/entities" /> },
      { path: '/entities', exact: true, element: <Entities /> },
      { path: '/add-property/:id', exact: true, element: <AddProperty /> },
      { path: '/property/:address', exact: true, element: <Property /> },
      { path: '/profile', exact: true, element: <Profile /> },
      { path: '/transactions', exact: true, element: <TransactionsNew /> },
      { path: '/reports', exact: true, element: <Reports /> },
      //{ path: '/payment', exact: true, element: <Payment /> },
      
      /*{ path: '/about', exact: true, element: <About /> },
      { path: '/ui/alerts', exact: true, element: <Alerts /> },
      { path: '/badges', exact: true, element: <Badges /> },
      { path: '/buttons', exact: true, element: <Buttons /> },
      { path: '/cards', exact: true, element: <Cards /> },
      { path: '/grid', exact: true, element: <Grid /> },
      { path: '/table', exact: true, element: <Tables /> },
      { path: '/forms', exact: true, element: <Forms /> },
      { path: '/breadcrumbs', exact: true, element: <Breadcrumbs /> },*/
      { path: '/web/external/connect', exact: true, element: <ConnectExternal /> },
      { path: '/web/external/manage', exact: true, element: <ManageExternal /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'register', element: <Register /> },
      { path: 'login', element: <Login /> },
      { path: 'password-recover', element: <Recover /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
