import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { firebase } from '../firebase/Firebase';
import { hasSubscription, getCustomerFromStripe, getUserByUid, updateSubscription, getExternalAccounts } from '../../data/mongo';

const initialState = {
  isValid: false,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'STRIPE_STATE_CHANGED') {
    const { isValid, loading } = action.payload;
    
    const newState = {
      isValid,
      loading
    }
    
    return newState;
  }

  return state;
};

const SubscriptionContext = createContext({
  ...initialState,
  platform: 'Stripe',
  validateSubscription: () => Promise.resolve(),
});

export const SubscriptionProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  // Validate Subscription
  const validateSubscription = () => {
    return hasSubscription();
  }

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.


          getUserByUid().then((userResp) => {
            //Get External Accounts to be able to show notifications if an account has an error from Plaid
            getExternalAccounts(userResp.id);
            
            if (userResp) {
              validateSubscription()
                .then((response) => {
                  if (response) {
                    getCustomerFromStripe(response.customerId)
                      .then((cResp) => {
                        if (cResp.delinquent === false  && cResp.subscriptions.data.length !== 0) {
                          response.isCurrent = true;
                          sessionStorage.setItem("subscription", JSON.stringify(response));
                          updateSubscription(response.id,true);
                          dispatch({
                            type: 'STRIPE_STATE_CHANGED',
                            payload: {
                              isValid: true,
                              loading: false
                            },
                          });
                        } else {
                          response.isCurrent = false;
                          sessionStorage.setItem("subscription", JSON.stringify(response));
                          updateSubscription(response.id,false);
                          dispatch({
                            type: 'STRIPE_STATE_CHANGED',
                            payload: {
                              isValid: false,
                              loading: false
                            },
                          });
                        }
                      }).catch(() => {
                        dispatch({
                          type: 'STRIPE_STATE_CHANGED',
                          payload: {
                            isValid: false,
                            loading: false
                          },
                        });
                      });
                  } else {
                    dispatch({
                      type: 'STRIPE_STATE_CHANGED',
                      payload: {
                        isValid: false,
                        loading: false
                      },
                    });
                  }
                });
            } else {
              dispatch({
                type: 'STRIPE_STATE_CHANGED',
                payload: {
                  isValid: false,
                  loading: false
                },
              });
            }
          });
        }
      }),
    [dispatch],
  );


  
  return (
    <SubscriptionContext.Provider
      value={{
        ...state,
        platform: 'Stripe',
        validateSubscription
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriptionContext;