import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
import environmentVars from '../../environments/env.json';

const firebaseConfig = {
  apiKey: environmentVars.FIREBASE_API_KEY,
  authDomain: environmentVars.FIREBASE_AUTH_DOMAIN,
  projectId: environmentVars.FIREBASE_PROJECT_ID,
  //storageBucket: 'reactprojects-b6bf1.appspot.com',
  //messagingSenderId: '645018563926',
  //appId: '1:645018563926:web:ef4544ee772f67871a12f7',
  //measurementId: 'G-Y27B5MKNDM',
  //databaseURL: 'https://reactprojects-b6bf1-default-rtdb.firebaseio.com/',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Db = firebase.database();
const Auth = firebase.auth();

export { Db, Auth, firebase };
