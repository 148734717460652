import axios from 'axios';
import moment from 'moment';
// import { trackPromise } from 'react-promise-tracker';
import { firebase } from '../components/firebase/Firebase';


export const loadSessionItems = (sessionKey) => {
  return JSON.parse(sessionStorage.getItem(sessionKey)) || [];
};

export const storeSessionItems = (sessionKey, items) => {
  sessionStorage.setItem(sessionKey, JSON.stringify(items));
};

export const loadSessionItemsAsync = async(sessionKey) => {
  return JSON.parse(sessionStorage.getItem(sessionKey)) || [];
};

export const storeSessionItemsAsync = async(sessionKey, items) => {
  sessionStorage.setItem(sessionKey, JSON.stringify(items));
};

const delay = ms => new Promise(res => setTimeout(res, ms));

async function getToken(wait = false) {
    if (wait) {
        await delay(3000);
    }
    const auth = JSON.parse(sessionStorage.getItem('auth')) || '';
    let refreshToken = false;
    let token = '';

    if (auth) {
        token = auth.token;
        if (moment(token.datetime).add(1, 'hours') < moment()) {
            refreshToken = true;
        }
    }

    if (!auth || refreshToken) {
        if (firebase.auth().currentUser) {
            await firebase.auth().currentUser.getIdToken(true).then((resp) => {
                const tokenInfo = {
                    token: resp,
                    datetime: new Date()
                  }
                sessionStorage.setItem('auth', JSON.stringify(tokenInfo));
                token = resp;
            });
        }
    }
    
    return token;
}

export const getRequest = async (url, wait = false) => {
    return getToken(wait).then((result) => {
        const headers = {
            "Authorization": `Bearer ${result}`
        };
        return axios.get(url, { headers });
    })
}

export const deleteRequest = async (url, wait = false) => {
    return getToken(wait).then((result) => {
        const headers = {
            "Authorization": `Bearer ${result}`
        };
        return axios.delete(url, { headers });
    })
}

export const postRequest = async (url, body, wait = false) => {
    return getToken(wait).then((result) => {
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${result}`
        };
        
        return axios.post(url, body, { headers });
    })
}

export const putRequest = async (url, body, wait = false) => {
    return getToken(wait).then((result) => {
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${result}`
        };
        
        return axios.put(url, body, { headers });
    })
}