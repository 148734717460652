import React from 'react';
// import { useSelector } from 'react-redux';

// import { ReactComponent as LogoDarkIcon } from '../../assets/images/logos/dark-logo-icon.svg';
// import { ReactComponent as LogoDarkText } from '../../assets/images/logos/dark-logo-text.svg';
// import { ReactComponent as LogoWhiteIcon } from '../../assets/images/logos/white-logo-icon.svg';
// import { ReactComponent as LogoWhiteIcon } from '../../assets/images/logos/logo.svg';
// import { ReactComponent as LogoWhiteText } from '../../assets/images/logos/white-logo-text.svg';
import logo from '../../assets/images/logos/RD_black_letters.png';

const AuthLogo = () => {
  // const isDarkMode = useSelector((state) => state.customizer.isDark);

  return (
    <div className="p-4 d-flex align-items-center justify-content-center gap-2">
      <img src={logo} alt="logo" style={{maxWidth: "50%", margin: "0 auto", objectFit:"cover"}} />
      {/* {isDarkMode !== false ? (
        <>
          <LogoWhiteIcon />
          <LogoWhiteText />
        </>
      ) : (
        <>
          <LogoWhiteIcon />
          <LogoDarkText />
        </>
      )} */}
    </div>
  );
};

export default AuthLogo;
