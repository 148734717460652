import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner } from "reactstrap";
import PropTypes from 'prop-types';
import useAuth from './useAuth';
import Login from '../../views/auth/Login';
import Error from '../../views/auth/Error';

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const hasSession = sessionStorage.getItem("auth");
  if (!auth.isAuthenticated && !hasSession) {
    // console.log('logging in2', requestedLocation);
    return <Login />;
  }
  //console.log('logging in6', auth);

  const userInfoExists = sessionStorage.getItem("userInfo");
  if (!userInfoExists) {
    return <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
      <Spinner color="primary" />
    </div>;
  }

  if (!auth.isAuthenticated && location.pathname === '/') {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
      return <Error />;
    }
    //console.log('logging in5', requestedLocation);
    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    //console.log('logging in3', requestedLocation);
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  //console.log('logging in4', children);
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
