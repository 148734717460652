import React from 'react';
import ReactDOM from 'react-dom';
import './_apis_/account';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { usePromiseTracker } from 'react-promise-tracker';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './App';
import { AuthProvider } from './components/firebase/FirebaseContext';
import { SubscriptionProvider } from './components/authGurad/SubscriptionContext';
import environmentVars from './environments/env.json';
//import { AuthProvider } from './components/jwt/JwtContext';


//(async() => {
  //returns {"publishableKey":"pk_test_51KT83REibDRqyRueT9npIFudkDziz1lty43J4sqviYIDG3DZ019SlHxUlOsHGbd4osB0afd39suv5rfVKPu90OLS00gcFofUYF"}
  //const { publishableKey } = await fetch('/config').then(r => r.json());
  const stripePromise = loadStripe(environmentVars.STRIPE_KEY);
  // const LoadingIndicator = () => {
  //   const { promiseInProgress } = usePromiseTracker();
  //   console.log(promiseInProgress);
  //   return (
  //     promiseInProgress &&
  //       <h1>Hey some async call in progress ! </h1>
  //   );  
  // }

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Elements stripe={stripePromise}>
            <SubscriptionProvider>
              <App />
              {/* <LoadingIndicator/> */}
            </SubscriptionProvider>
          </Elements>
        </AuthProvider>
      </BrowserRouter>
    </Provider>,

    document.getElementById('root'),
  );
//})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
