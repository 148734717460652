import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Spinner } from "reactstrap";
import useSubscription from './useSubscription';


const SubscriptionGuard = (props) => {
  const { children } = props;
  const subscription = useSubscription();
  const subscriptionSess = JSON.parse(sessionStorage.getItem("subscription")) || [];
  let isCurrent = false;

  if (subscription.loading) {
    return <Spinner color="primary" />
  }
  
  if (subscriptionSess) {
    isCurrent = subscriptionSess.isCurrent;
  }

  if (!isCurrent && !subscription.isValid) {
    return <Navigate to='/account' />; 
  }

  // const auth = sessionStorage.getItem("auth");
  // const userInfo = sessionStorage.getItem("userInfo");

  // if (!auth || !userInfo) {
  //   return <Spinner color="primary" />;
  // }

  
  return <>{children}</>;
};

SubscriptionGuard.propTypes = {
  children: PropTypes.node,
};

export default SubscriptionGuard;
