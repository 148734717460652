import environmentVars from '../environments/env.json';
import { postRequest, putRequest, getRequest, deleteRequest, loadSessionItems, storeSessionItems, storeSessionItemsAsync } from './fetchCalls';

const apiUrl = environmentVars.REACT_APP_SERVICE_API;

const convertEntity = async(result) => {
    let children = [];
    const entities = [];
    if (result) {
        result.forEach((item) => {
            const entityResult = {};

            item.property.forEach((prop) => {
                const propertyValues = {};
                propertyValues.label = prop.address;
                propertyValues.parent = item.name;
                propertyValues.value = prop.name;
                propertyValues.id = prop.id;
                propertyValues.city = prop.city;
                propertyValues.state = prop.state;
                propertyValues.zip = prop.zip;
                propertyValues.acquisitionPrice = prop?.acquisitionPrice;
                propertyValues.marketValue = prop?.marketValue;
                propertyValues.loanBalance = prop?.loanBalance;
                propertyValues.rentAmount = prop?.rentAmount;
                propertyValues.rentOutstanding = prop?.rentOutstanding;
                propertyValues.cash = prop?.cash;
                propertyValues.unit = prop?.unit;
                
                propertyValues.tenantName = prop?.tenantName;
                propertyValues.leaseStart = prop?.leaseStart;
                propertyValues.leaseEnd = prop?.leaseEnd;
                propertyValues.beds = prop?.beds;
                propertyValues.baths = prop?.baths;
                propertyValues.numUnits = prop?.numUnits;
                propertyValues.acquisitionDate = prop?.acquisitionDate;
                propertyValues.remodelingCosts = prop?.remodelingCosts;
                propertyValues.loanPayment = prop?.loanPayment;

                children.push(propertyValues);
            });

            entityResult.label = item.name;
            entityResult.parent = item.id;
            entityResult.value = `${item.id}:${item.name}`;
            entityResult.children = children;

            entities.push(entityResult);
            children = [];
        });
        
        return entities;
    }
    return null;
}

export const getEntities = async(reload = false) => {
    if (!reload) {
        const getSessionDataEntities = loadSessionItems("entities") || [];
        if (getSessionDataEntities.length > 0 || Object.keys(getSessionDataEntities).length > 0) {
            return getSessionDataEntities;
        }
    }

    const result = await getRequest(`${apiUrl}/entities`);

    if (result) {
        const obj = await convertEntity(result.data);
        storeSessionItems("entities", obj);
        return obj;
    }
    return null;
}


export const addEntity = async (name) => {    
    const body = `{"name": "${name}"}`;
    const entitiesCall = await postRequest(`${apiUrl}/entities`, body);
    return entitiesCall;
};

export const updateAccountName = async (id, name) => {    
    const body = `{"accountName": "${name}"}`;
    const accountCall = await putRequest(`${apiUrl}/account/${id}`, body);

    const currentAccount = loadSessionItems("accounts") || [];
    const indexOfArrayToChange = currentAccount.findIndex(acct => acct.id === accountCall.data.id);

    currentAccount[indexOfArrayToChange].accountName = accountCall.data.accountName;
    await storeSessionItemsAsync("accounts", currentAccount);

    return accountCall;
};

export const updateProperty = async (id, name, value) => {    
    const body = `{}`;
    const propertyCall = await putRequest(`${apiUrl}/entities/property/${id}/${name}/${value}`, body);

    getEntities(true);

    return propertyCall;
};

export const updateEntityName = async (id, name, previousValue) => {    
    const body = `{"name": "${name}"}`;
    const entitiesCall = await putRequest(`${apiUrl}/entities/${id}/${previousValue}`, body);

    const obj = await convertEntity([entitiesCall.data]);
    const currentEntities = loadSessionItems("entities") || [];
    const indexOfArrayToChange = currentEntities.findIndex(ent => ent.parent === entitiesCall.data.id);
    const newValue = obj[0];
    
    currentEntities[indexOfArrayToChange] = newValue;
    await storeSessionItemsAsync("entities", currentEntities);

    return entitiesCall;
};

export const addProperty = async (entity, address, aCity, state, postcode) => {    
    const body = `{"entity": "${entity}", "address": "${address}", "city": "${aCity}", "state": "${state}", "postcode": "${postcode}"}`;
    const entitiesCall = await postRequest(`${apiUrl}/entities/addProperty`, body);

    const obj = await convertEntity([entitiesCall.data]);
    const currentEntities = loadSessionItems("entities") || [];
    const indexOfArrayToChange = currentEntities.findIndex(ent => ent.parent === entitiesCall.data.id);
    const newValue = obj[0];
    
    currentEntities[indexOfArrayToChange] = newValue;
    await storeSessionItemsAsync("entities", currentEntities);
    return entitiesCall;
};

export const deleteEntity = async (id) => {
    const result = await deleteRequest(`${apiUrl}/entities/${id}`);
    if (result) {
        return result.data; 
    }

    return null;
 };

 export const deleteAccount = async (id) => {
    const result = await deleteRequest(`${apiUrl}/account/${id}`);
    if (result) {
        return result.data; 
    }

    return null;
 };

 export const deleteTransaction = async (id) => {
    const result = await deleteRequest(`${apiUrl}/transactions/${id}`);
    if (result) {
        return result.data; 
    }

    return null;
 };

export const deleteProperty = async (entity, property) => {    
    const body = `{"entity": "${entity}", "property": "${property}"}`;
    const entitiesCall = await postRequest(`${apiUrl}/entities/deleteProperty`, body);

    const obj = await convertEntity([entitiesCall.data]);
    const currentEntities = loadSessionItems("entities") || [];
    const indexOfArrayToChange = currentEntities.findIndex(ent => ent.parent === entitiesCall.data.id);
    const newValue = obj[0];
    
    currentEntities[indexOfArrayToChange] = newValue;
    await storeSessionItemsAsync("entities", currentEntities);

    return entitiesCall;
};

export const addUser = async (user, firstName, lastName) => {    
    const body = `{"email": "${user.email}", "uid": "${user.uid}", "firstName": "${firstName}", "lastName": "${lastName}"}`;
    const userCall = await postRequest(`${apiUrl}/users`, body);
    return userCall;
};

export const updateUser = async (firstName, lastName) => {    
    const body = `{"firstName": "${firstName}", "lastName": "${lastName}"}`;
    const userCall = await putRequest(`${apiUrl}/users`, body);   
    return userCall;
};

export async function getExternalAccounts(uid, refresh = true) {
    const getSessionData = loadSessionItems("accounts") || [];
    if (getSessionData.length > 0 || Object.keys(getSessionData).length > 0) {
        return getSessionData;
    }

    if (refresh) {
        const result = await postRequest(`${apiUrl}/external/pl/getItems`, {'userId': uid });

        if (result.data.data.length > 0) {
            const accounts = result.data.data;
            storeSessionItems("accounts", accounts);
            return accounts; 
        }
    }

    return null;
 };

const delay = ms => new Promise(res => setTimeout(res, ms));

export async function getUserByUid(wait = true, refresh = false) {
    if (!refresh) {
        if (wait) {
            await delay(2000);
        }

        const getSessionData = loadSessionItems("userInfo") || [];
        if (getSessionData.length > 0 || Object.keys(getSessionData).length > 0) {
            return getSessionData;
        }
    }
    
    const result = await getRequest(`${apiUrl}/users`, wait);
    if (result.data.length) {
        const user = result.data[0];
        storeSessionItems("userInfo", user);
        return user; 
    }
    return null;
 };

 export const getUserSubscription = async (sid) => {
    const result = await getRequest(`${apiUrl}/subscription/sid/${sid}`);
    if (result.data.length) {
        const subscription = result.data[0];
        return subscription; 
    }

    return null;
 };

 export const createTransaction = async (name, notes, amount, date, category = null, entity = null) => {    
    let body = `{"name": "${name}","notes": "${notes}", "amount": "${amount}", "date": "${date}"`;

    if (category) {
        body += `, "category": ${JSON.stringify(category)}`;
    }
    if (entity) {
        body += `, "entity": ${JSON.stringify(entity)}`;
    }
    body += `}`;

    const subCall = await postRequest(`${apiUrl}/transactions`, body);
    
    return subCall;
};
 
 export const addSubscription = async (customerId) => {    
    const body = `{"customerId": "${customerId}", "isCurrent": true}`;
    const subCall = await postRequest(`${apiUrl}/subscriptions`, body);    
    return subCall;
};

export const updateSubscription = async (userId, current) => {    
    const body = `{"isCurrent": ${current}}`;
    const subCall = await putRequest(`${apiUrl}/subscriptions/${userId}`, body);
    return subCall;
};


export const hasSubscription = async () => {
    const getSessionData = loadSessionItems("subscription");
    if (getSessionData.length > 0 || Object.keys(getSessionData).length > 0) {
        return getSessionData;
    }
    
    const result = await getRequest(`${apiUrl}/subscriptions`);
    if (result.data.length) {
        const subscription = result.data[0];
        storeSessionItems("subscription", subscription);
        return subscription;
    }

    return null;
};


export const addRecentApplication = async () => {        
   await getRequest(`${apiUrl}/homes`);
};


export const getCustomerFromStripe = async (customerId) => {
    const getSessionData = loadSessionItems("stripe.customer");
    if (getSessionData.length > 0 || Object.keys(getSessionData).length > 0) {
        return getSessionData;
    }

    const result = await getRequest(`${apiUrl}/payments/customer/${customerId}`);
    if (result) {
        const stripeCustomer = result.data;
        storeSessionItems("stripe.customer", stripeCustomer);
        return result.data; 
    }

    return null;
 };

export const getCustomerPaymentMethodsFromStripe = async (customerId, reload = false) => {
    if (!reload) {
        const getSessionData = loadSessionItems("stripe.methods");
        if (getSessionData.length > 0 || Object.keys(getSessionData).length > 0) {
            return getSessionData;
        }
    }

    const result = await getRequest(`${apiUrl}/payments/customer/methods/${customerId}`);
    if (result) {
        const stripeMethods = result.data;
        storeSessionItems("stripe.methods", stripeMethods);
        return result.data; 
    }
    return null;
 };


export const getCustomerPaymentsFromStripe = async (customerId) => {
    const getSessionData = loadSessionItems("stripe.payments");
    if (getSessionData.length > 0 || Object.keys(getSessionData).length > 0) {
        return getSessionData;
    }

    const result = await getRequest(`${apiUrl}/payments/customer/all/${customerId}`);
    if (result) {
        const stripePayments = result.data;
        storeSessionItems("stripe.payments", stripePayments);
        return result.data; 
    }
    return null;
 };

export const getTransactionData = async(type = null, value = null, fromDate = null, toDate = null, limit = 100, refresh = false) => {

    if (!refresh) {
        const getSessionDataTransactions = loadSessionItems("transactions") || [];

        if (getSessionDataTransactions.length > 0 || Object.keys(getSessionDataTransactions).length > 0) {
            if (getSessionDataTransactions.transactionData.length > 0 || Object.keys(getSessionDataTransactions.transactionData).length > 0) {
                return getSessionDataTransactions;
            }
        }
    }

    let url = `${apiUrl}/transactions/filter`;

    if (fromDate && toDate) {
        url = `${url}/from/${fromDate}/to/${toDate}`
    }

    url = `${url}/limit/${limit}`;

    if (type) {
        url = `${url}/type/${type}/value/${value}`;
    }

    const result = await getRequest(url);
    if (result) {
        storeSessionItems("transactions", result.data);
        return result.data;
    }
    return null;
}

// export const getTransactionsByEntity = async(tId, fromDate, toDate, entityType, id) => {
//     const apiResult = await getRequest(`${apiUrl}/transactions/getByEntity/${tId}/from/${fromDate}/to/${toDate}/type/${entityType}/id/${id}`);
//     const columns = [''];
//     const dataItems = [];
//     const results = [];
//     if (apiResult) {
//         Object.keys(apiResult.data).forEach((mainCategory) => {
//             Object.keys(apiResult.data[mainCategory]).forEach((key) => {
                
//                 if (key !== "category") {
//                     const dataItem = [apiResult.data[mainCategory].category];
//                     //dataItem.push(apiResult.data[mainCategory][key].subcategory);
//                     let total = 0;
//                     apiResult.data[mainCategory][key].children.forEach((item, idx) => {
                        
//                         dataItem.push(item.value);
//                         total += Number(item.value);

//                         if (idx === apiResult.data[mainCategory][key].children.length -1) {
//                             const anotherItem = {};
//                             anotherItem.name = "Total";
//                             anotherItem.value = total;
//                             apiResult.data[mainCategory][key].children.push(anotherItem);
//                             dataItem.push(total);
//                         }

//                         if (columns.includes(item.name.replaceAll('_', ' '))) {
//                             //dont do anything right now
//                         } else {
//                             columns.push(item.name.replaceAll('_', ' '));
//                         }
//                     });
//                     dataItems.push(dataItem);
                    
//                     results.columns = columns;
//                     results.data = dataItems;
//                 }

//             });
//         });


//         if (results.columns) {
//             results.columns.push('Total');
//         }

//         results.apiResult = apiResult;

//         return results;
        
//     }
    
//     return null;
// }

export const getTransactionInfo = async(tId, fromDate, toDate, entityType, entityValue, type, displayType = 'property') => {
    let url = '';
    
    if (displayType === 'property') {
        url = `${apiUrl}/transactions/getByGroup/reportType/${type}/${tId}/from/${fromDate}/to/${toDate}`
        if (entityType !== 'all') {
            url = `${apiUrl}/transactions/getByGroup/reportType/${type}/${tId}/from/${fromDate}/to/${toDate}/${entityType}/${entityValue}`;
        }
    } else if (displayType === 'month') {
        url = `${apiUrl}/transactions/getByDate/reportType/${type}/${tId}/from/${fromDate}/to/${toDate}`
        if (entityType !== 'all') {
            url = `${apiUrl}/transactions/getByDate/reportType/${type}/${tId}/from/${fromDate}/to/${toDate}/${entityType}/${entityValue}`;
        }
    }

    if (type === 'balanceSheet') {
        url = `${apiUrl}/transactions/get/reportType/${type}`;
        if (entityType !== 'all') {
            url = `${apiUrl}/transactions/get/reportType/${type}/${entityType}/${entityValue}`;
        }
    }
    

    const apiResult = await getRequest(url);

    const columns = [''];
    const dataItems = [];
    const results = [];
    // const totalColumns = {};

    if (apiResult) {
        Object.keys(apiResult.data.details).forEach((mainCategory) => {
            Object.keys(apiResult.data.details[mainCategory]).forEach((key) => {
                
                if (key !== "category" && key !== "sort") {
                    const dataItem = [apiResult.data.details[mainCategory].category];

                    let total = 0;
                    apiResult.data.details[mainCategory][key].children.forEach((item, idx) => {
                        
                        dataItem.push(item.value);
                        total += Number(item.value);

                        if (idx === apiResult.data.details[mainCategory][key].children.length -1) {
                            const anotherItem = {};
                            anotherItem.name = "Total";
                            anotherItem.value = total;
                            apiResult.data.details[mainCategory][key].children.push(anotherItem);
                            dataItem.push(total);
                        }

                        if (columns.includes(item.name.replaceAll('_', ' '))) {
                            //dont do anything right now
                        } else {
                            columns.push(item.name.replaceAll('_', ' '));
                        }
                    });
                    dataItems.push(dataItem);
                    
                    results.columns = columns;
                    results.data = dataItems;
                }

            });
        });


        if (results.columns) {
            results.columns.push('Total');
        }

        results.apiResult = apiResult;
        return results;
        
    }
    return null;
}

export const updateTransactions = async(transactionId, body) => {
    const subCall = await putRequest(`${apiUrl}/transactions/${transactionId}`, body);
    return subCall;
}

export const getCategories = async() => {
    const result = await getRequest(`${apiUrl}/categories`);
    if (result) {
        return result;
    }
    return null;
}

export const getProperty = async(address) => {
    const result = await getRequest(`${apiUrl}/entities/property/${address}`);

    if (result) {
        return result.data;
    }
    return null;
}