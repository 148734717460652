import { configureStore } from '@reduxjs/toolkit';

import CustomizerReducer from './customizer/CustomizerSlice';
import TransactionsReducer from './transactions/TransactionSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    transactionsReducer: TransactionsReducer
  },
});

export default store;
